/* eslint-disable indent */
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../store";
import {
  getDealsFromContact,
  getMPUpdateOptions,
  MPUpdatePostModel,
  postMPUpdate,
} from "../../../store/DataSender/actions";
import {
  selectAllDealsFromContact,
  selectAllMPOptions,
  selectDataSenderErrors,
  selectDataSenderLoading,
  selectSuccessfullyPostedMPUpdate,
} from "../../../store/DataSender/selector";
import "../../../styles/fields.scss";
import { sendToast } from "../../../utils/helpers";
import DefaultButton from "../../Buttons/DefaultButton";
import LoadingSmall from "../../Loading/LoadingSmall";
import { Dropdown, TextArea } from "../components";
import { Deal } from "../../../Interfaces/deal";

///
// FOR TESTING
// USE TESTING CONTACT VID IN URL PARAM 92837563 its in our seeder on autosourcer
// i.e /mp-update/?vid=92837563
///

const getQueryParams = () => {
  if (typeof window !== "undefined") {
    const urlParams = new URLSearchParams(window.location.search) as any;
    const entries = [...urlParams.entries()];
    const { vid, autosourcer_id } = entries.reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});
    return { vid, autosourcer_id };
  }
  return null;
};

const MPUpdateContainer = () => {
  const dispatch = useAppDispatch();
  const [notes, setNotes] = useState("");
  const [selectedDeal, setSelectedDeal] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("");
  const mpOptions = useSelector(state => selectAllMPOptions(state)) as string[];
  const deals = useSelector(state => selectAllDealsFromContact(state));
  const successfullyPostedMPUpdate = useSelector(state =>
    selectSuccessfullyPostedMPUpdate(state)
  ) as boolean;
  const loading = useSelector(state => selectDataSenderLoading(state));
  const errors = useSelector(state =>
    selectDataSenderErrors(state)
  ) as string[];

  useEffect(() => {
    if (selectedDeal) {
      // Refetch mpUpdateOptions when deal has changed.
      const params = getQueryParams();
      if (!params) {
        return;
      }
      const { vid } = params;
      dispatch(getMPUpdateOptions({ contactId: vid, dealId: selectedDeal }));
    }
  }, [selectedDeal]);

  useEffect(() => {
    // Fetch mp options
    const params = getQueryParams();
    if (!params) {
      return;
    }
    const { vid, autosourcer_id } = params;
    if (!vid) {
      sendToast("No Vid was attached, please retry.", { type: "error" });
      return;
    }
    dispatch(getMPUpdateOptions({ contactId: vid }));
    dispatch(getDealsFromContact(autosourcer_id));
  }, []);

  useEffect(() => {
    if (errors && errors.length > 0) {
      const errorString = errors.join("\n");
      sendToast(errorString, { type: "error" });
    }
  }, [errors]);

  const postUpdate = () => {
    if (!selectedStatus) {
      sendToast("Please select a status", { type: "error" });
      return;
    }
    if (!notes) {
      sendToast("Please provide some notes", { type: "error" });
      return;
    }
    const params = getQueryParams();
    if (!params) {
      return;
    }
    const { vid } = params;
    if (!vid) {
      sendToast("No Vid was attached, please retry.", { type: "error" });
      return;
    }
    const mpPostProps = {
      status: selectedStatus,
      notes,
      deal_id: selectedDeal || undefined,
      contactId: vid,
    } as MPUpdatePostModel;
    dispatch(postMPUpdate(mpPostProps));
  };

  const organizedDealOptions = useMemo(() => {
    // Sort deals
    // { label: '', value: '' }
    const organized = deals
      ? deals.map((deal: Deal) => ({
          label: `${deal.customer.firstname} ${deal.customer.lastname} - ${
            deal.transaction_type
          }er (${
            deal.customer.acquisition_channel === "Mortgage Partners"
              ? "MP Deal"
              : "NOT MP Deal"
          }) ID: ${deal.id} `,
          value: deal.id,
        }))
      : [];
    return organized;
  }, [deals]);

  const dropDownFieldItem = {
    label: "MP Status Options",
    keyName: "status",
    options: mpOptions,
    required: true,
  };

  const notesFieldItem = {
    label: "Notes",
    keyName: "notes",
    required: true,
  };

  // set up deals dropdown.
  const dealDropdownFieldItem = {
    label: "Contact Deals",
    keyName: "deal",
    options: organizedDealOptions,
    required: false,
  };

  return (
    <div className="defaultFormContainer">
      <h2>MP Update Form</h2>
      <div className="defaultFormBody">
        {!loading && mpOptions && !successfullyPostedMPUpdate && (
          <>
            <p>Enter all the fields below and click Submit MP Update.</p>
            <Dropdown
              fieldItem={dealDropdownFieldItem}
              onChange={val => setSelectedDeal(val)}
              value={selectedDeal}
            />
            <Dropdown
              fieldItem={dropDownFieldItem}
              onChange={val => setSelectedStatus(val)}
              value={selectedStatus}
            />
            <TextArea
              fieldItem={notesFieldItem}
              value={notes}
              onChange={val => setNotes(val)}
            />
            <DefaultButton
              text="Submit MP Update"
              className="defaultButton"
              disabled={!selectedStatus || !notes}
              callback={postUpdate}
            />
          </>
        )}
        {successfullyPostedMPUpdate && (
          <p>You&apos;ve successfully submitted the mp update! 🎉🎉</p>
        )}
      </div>
      {loading && (
        <div className="loadingContainer">
          <p>Loading</p>
          <div>
            <LoadingSmall />
          </div>
        </div>
      )}
    </div>
  );
};

export default MPUpdateContainer;
