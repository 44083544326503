import React from "react";
import AuthenticationLayout from "../components/AuthenticationLayout";
import MPUpdateContainer from "../components/FormBuilder/DataSenderContainer/MPUpdateContainer";
import "../styles/form.scss";

const MPUpdate = () => (
  <AuthenticationLayout>
    <MPUpdateContainer />
  </AuthenticationLayout>
);

export default MPUpdate;
