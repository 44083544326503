export const selectSuccessfullySentData = (state: any) =>
  state.dataSender.successfullySentData;
export const selectDataSenderErrors = (state: any) => state.dataSender.errors;
export const selectDataSenderLoading = (state: any) => state.dataSender.loading;

export const selectDataSenderData = (state: any) =>
  state.dataSender.dataSenderData;
export const selectDataSenderObject = (state: any) =>
  state.dataSender.dataSenderObject;

export const selectAllDataSenders = (state: any) =>
  state.dataSender.allDataSenders;
export const selectAllMPOptions = (state: any) => state.dataSender.mpOptions;
export const selectSuccessfullyPostedMPUpdate = (state: any) =>
  state.dataSender.successfullyPostedMPUpdate;
// Deals
export const selectAllDealsFromContact = (state: any) =>
  state.dataSender.contactDeals;
