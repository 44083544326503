import React from "react";
import "./LoadingSmall.scss";

const LoadingSmall = () => (
  <div className="lds-roller">
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
  </div>
);

export default LoadingSmall;
